import React from "react"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <footer className="bg-gray-900 max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-18 lg:px-8">
      <div className="xl:grid xl:grid-cols-3 xl:gap-8">
        <div className="xl:col-span-1">
          <svg
            className="block h-8"
            viewBox="0 0 273 50"
            fill="currentColor"
            preserveAspectRatio="xMidYMid meet"
          >
            <path
              d="M10.975.422c1.031 0 1.93.047 2.695.14a16.96 16.96 0 012.18.399 23.16 23.16 0 012.039.562c.672.22 1.422.461 2.25.727l-.094 2.46a60.972 60.972 0 00-1.804-.585 21.544 21.544 0 00-4.032-.82 19.5 19.5 0 00-2.414-.14c-.39 0-.844.062-1.36.187a4.908 4.908 0 00-1.476.632 4.063 4.063 0 00-1.195 1.172c-.328.485-.492 1.086-.492 1.805 0 .672.265 1.46.797 2.367.531.89 1.422 2.008 2.672 3.352l4.125 4.453a104.34 104.34 0 012.601 2.789 25.336 25.336 0 012.203 2.695c.625.875 1.125 1.75 1.5 2.625.375.86.563 1.711.563 2.555 0 1.64-.305 3.039-.914 4.195-.594 1.14-1.375 2.07-2.344 2.79a9.658 9.658 0 01-3.258 1.546 14.012 14.012 0 01-3.656.492c-1.063 0-2.055-.062-2.977-.187a17.655 17.655 0 01-2.648-.516 17.24 17.24 0 01-2.39-.82 24.125 24.125 0 01-2.274-1.172l.305-2.531 1.875.867c.64.281 1.289.539 1.945.773.672.22 1.351.399 2.039.54.687.14 1.375.21 2.062.21 2.297 0 4.032-.468 5.204-1.406 1.171-.953 1.757-2.328 1.757-4.125 0-.547-.125-1.101-.375-1.664a8.988 8.988 0 00-.984-1.734 18.746 18.746 0 00-1.5-1.875c-.563-.64-1.164-1.313-1.805-2.016l-4.172-4.547a181.279 181.279 0 01-2.671-3.328c-.72-.922-1.297-1.742-1.735-2.46-.422-.72-.726-1.376-.914-1.97a6.96 6.96 0 01-.258-1.898c0-1.266.258-2.313.774-3.14a5.856 5.856 0 012.039-2.016c.844-.5 1.797-.852 2.86-1.055a16.114 16.114 0 013.257-.328z"
              fill="url(#paint0_linear)"
            />
            <path
              d="M47.894 1.148v2.297h-8.438V36h-5.133V3.445h-8.507V1.148h22.078z"
              fill="url(#paint1_linear)"
            />
            <path
              d="M75.766 1.148h5.132V36h-5.132v-2.742l-5.72 1.922c-.78.265-1.57.492-2.366.68-.797.187-1.57.343-2.32.468a22.53 22.53 0 01-2.133.258c-.672.062-1.266.094-1.782.094-1.11 0-2.054-.125-2.836-.375-.78-.235-1.43-.54-1.945-.914a4.967 4.967 0 01-1.219-1.29 6.897 6.897 0 01-.68-1.359 6.837 6.837 0 01-.28-1.195 11.056 11.056 0 01-.047-.82V1.148h5.156v27.563c0 .297.031.719.093 1.266.063.53.243 1.062.54 1.593.296.516.757.97 1.382 1.36.625.39 1.5.586 2.625.586.922 0 1.82-.047 2.696-.141a27.825 27.825 0 002.671-.422 40.459 40.459 0 002.883-.703c1-.297 2.094-.648 3.282-1.055V1.148z"
              fill="url(#paint2_linear)"
            />
            <path
              d="M115.708 18.633c0-2.125-.242-3.977-.727-5.555-.484-1.578-1.14-2.93-1.969-4.055a11.067 11.067 0 00-2.906-2.765 14.63 14.63 0 00-3.515-1.711 18.572 18.572 0 00-3.821-.867 31.73 31.73 0 00-3.797-.235h-2.93v30.258h2.93c1.235 0 2.5-.078 3.797-.234a19.616 19.616 0 003.821-.844 15.901 15.901 0 003.515-1.71 11.231 11.231 0 002.906-2.743c.829-1.125 1.485-2.469 1.969-4.031.485-1.563.727-3.399.727-5.508zm5.437-.094c0 2.438-.437 4.719-1.312 6.844a15.38 15.38 0 01-3.844 5.531c-1.703 1.578-3.82 2.82-6.352 3.727-2.515.906-5.429 1.359-8.742 1.359h-9.984V1.148h9.984c3.313 0 6.227.454 8.742 1.36 2.532.906 4.649 2.148 6.352 3.726a15.116 15.116 0 013.844 5.532c.875 2.109 1.312 4.367 1.312 6.773z"
              fill="url(#paint3_linear)"
            />
            <path
              d="M134.064 36h-5.156V1.148h5.156V36z"
              fill="url(#paint4_linear)"
            />
            <path
              d="M160.108 36.68c-1.719 0-3.375-.22-4.969-.657a19.924 19.924 0 01-4.453-1.828 19.216 19.216 0 01-3.774-2.86 18.855 18.855 0 01-2.906-3.679 18.725 18.725 0 01-1.898-4.36 17.976 17.976 0 01-.656-4.874c0-1.656.218-3.258.656-4.805a18.275 18.275 0 011.898-4.336 18.858 18.858 0 012.906-3.68 18.514 18.514 0 013.774-2.835 19.927 19.927 0 014.453-1.829 18.687 18.687 0 014.969-.656c1.703 0 3.344.219 4.922.656a18.124 18.124 0 014.429 1.829 17.94 17.94 0 013.75 2.836 17.732 17.732 0 012.907 3.68 17.627 17.627 0 011.875 4.335c.453 1.547.679 3.149.679 4.805 0 1.687-.226 3.312-.679 4.875a18.05 18.05 0 01-4.782 8.039 18.603 18.603 0 01-3.75 2.86 19.434 19.434 0 01-4.429 1.827 18.34 18.34 0 01-4.922.657zm0-2.274c2.125 0 4-.414 5.625-1.242a11.946 11.946 0 004.125-3.398c1.109-1.438 1.945-3.125 2.508-5.063.578-1.953.867-4.047.867-6.281 0-2.219-.281-4.297-.844-6.235-.562-1.937-1.398-3.617-2.508-5.039a11.593 11.593 0 00-4.101-3.351c-1.641-.828-3.532-1.242-5.672-1.242-2.172 0-4.078.414-5.719 1.242a11.874 11.874 0 00-4.102 3.351c-1.093 1.422-1.921 3.102-2.484 5.04-.547 1.937-.82 4.015-.82 6.234 0 2.234.281 4.328.844 6.281.562 1.938 1.39 3.625 2.484 5.063a11.928 11.928 0 004.125 3.398c1.641.828 3.531 1.242 5.672 1.242z"
              fill="url(#paint5_linear)"
            />
            <path
              d="M219.836 1.148v2.297h-16.195v10.57h15.703v2.297h-15.703v17.391h16.195V36h-21.328V1.148h21.328z"
              fill="url(#paint6_linear)"
            />
            <path
              d="M234.841.422a22.5 22.5 0 012.695.14c.781.094 1.508.227 2.18.399.687.156 1.367.344 2.039.562.672.22 1.422.461 2.25.727l-.094 2.46a60.612 60.612 0 00-1.805-.585 21.502 21.502 0 00-4.031-.82c-.75-.094-1.555-.14-2.414-.14-.391 0-.844.062-1.359.187a4.909 4.909 0 00-1.477.632 4.057 4.057 0 00-1.195 1.172c-.328.485-.493 1.086-.493 1.805 0 .672.266 1.46.797 2.367.532.89 1.422 2.008 2.672 3.352l4.125 4.453a104.53 104.53 0 012.602 2.789 25.297 25.297 0 012.203 2.695c.625.875 1.125 1.75 1.5 2.625.375.86.562 1.711.562 2.555 0 1.64-.304 3.039-.914 4.195-.593 1.14-1.375 2.07-2.343 2.79a9.664 9.664 0 01-3.258 1.546 14.016 14.016 0 01-3.656.492c-1.063 0-2.055-.062-2.977-.187a17.627 17.627 0 01-2.648-.516 17.223 17.223 0 01-2.391-.82 24.088 24.088 0 01-2.274-1.172l.305-2.531 1.875.867c.641.281 1.289.539 1.945.773a18.66 18.66 0 002.04.54c.687.14 1.375.21 2.062.21 2.297 0 4.031-.468 5.203-1.406 1.172-.953 1.758-2.328 1.758-4.125 0-.547-.125-1.101-.375-1.664a9.011 9.011 0 00-.984-1.734 18.774 18.774 0 00-1.5-1.875c-.563-.64-1.164-1.313-1.805-2.016l-4.172-4.547a184.604 184.604 0 01-2.672-3.328c-.719-.922-1.297-1.742-1.734-2.46-.422-.72-.727-1.376-.914-1.97a6.955 6.955 0 01-.258-1.898c0-1.266.258-2.313.773-3.14a5.865 5.865 0 012.039-2.016c.844-.5 1.797-.852 2.86-1.055a16.107 16.107 0 013.258-.328z"
              fill="url(#paint7_linear)"
            />
            <path
              d="M260.041.422a22.5 22.5 0 012.695.14c.781.094 1.508.227 2.18.399.687.156 1.367.344 2.039.562.672.22 1.422.461 2.25.727l-.094 2.46a60.612 60.612 0 00-1.805-.585 21.502 21.502 0 00-4.031-.82c-.75-.094-1.555-.14-2.414-.14-.391 0-.844.062-1.359.187a4.909 4.909 0 00-1.477.632 4.057 4.057 0 00-1.195 1.172c-.328.485-.493 1.086-.493 1.805 0 .672.266 1.46.797 2.367.532.89 1.422 2.008 2.672 3.352l4.125 4.453a104.53 104.53 0 012.602 2.789 25.297 25.297 0 012.203 2.695c.625.875 1.125 1.75 1.5 2.625.375.86.562 1.711.562 2.555 0 1.64-.304 3.039-.914 4.195-.593 1.14-1.375 2.07-2.343 2.79a9.664 9.664 0 01-3.258 1.546 14.016 14.016 0 01-3.656.492c-1.063 0-2.055-.062-2.977-.187a17.627 17.627 0 01-2.648-.516 17.223 17.223 0 01-2.391-.82 24.088 24.088 0 01-2.274-1.172l.305-2.531 1.875.867c.641.281 1.289.539 1.945.773a18.66 18.66 0 002.04.54c.687.14 1.375.21 2.062.21 2.297 0 4.031-.468 5.203-1.406 1.172-.953 1.758-2.328 1.758-4.125 0-.547-.125-1.101-.375-1.664a9.011 9.011 0 00-.984-1.734 18.774 18.774 0 00-1.5-1.875c-.563-.64-1.164-1.313-1.805-2.016l-4.172-4.547a184.604 184.604 0 01-2.672-3.328c-.719-.922-1.297-1.742-1.734-2.46-.422-.72-.727-1.376-.914-1.97a6.955 6.955 0 01-.258-1.898c0-1.266.258-2.313.773-3.14a5.865 5.865 0 012.039-2.016c.844-.5 1.797-.852 2.86-1.055a16.107 16.107 0 013.258-.328z"
              fill="url(#paint8_linear)"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M273 44H0v-1h273v1zM205 50H69v-1h136v1z"
              fill="#D4AD61"
            />
            <defs>
              <linearGradient
                id="paint0_linear"
                x1="17.747"
                y1="36.46"
                x2="56.531"
                y2="-96.446"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset=".07" stopColor="#D4AD61" />
                <stop offset=".34" stopColor="#D4AD61" />
                <stop offset="1" stopColor="#D4AD61" stopOpacity=".28" />
              </linearGradient>
              <linearGradient
                id="paint1_linear"
                x1="17.747"
                y1="36.46"
                x2="56.531"
                y2="-96.446"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset=".07" stopColor="#D4AD61" />
                <stop offset=".34" stopColor="#D4AD61" />
                <stop offset="1" stopColor="#D4AD61" stopOpacity=".28" />
              </linearGradient>
              <linearGradient
                id="paint2_linear"
                x1="17.747"
                y1="36.46"
                x2="56.531"
                y2="-96.446"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset=".07" stopColor="#D4AD61" />
                <stop offset=".34" stopColor="#D4AD61" />
                <stop offset="1" stopColor="#D4AD61" stopOpacity=".28" />
              </linearGradient>
              <linearGradient
                id="paint3_linear"
                x1="17.747"
                y1="36.46"
                x2="56.531"
                y2="-96.446"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset=".07" stopColor="#D4AD61" />
                <stop offset=".34" stopColor="#D4AD61" />
                <stop offset="1" stopColor="#D4AD61" stopOpacity=".28" />
              </linearGradient>
              <linearGradient
                id="paint4_linear"
                x1="17.747"
                y1="36.46"
                x2="56.531"
                y2="-96.446"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset=".07" stopColor="#D4AD61" />
                <stop offset=".34" stopColor="#D4AD61" />
                <stop offset="1" stopColor="#D4AD61" stopOpacity=".28" />
              </linearGradient>
              <linearGradient
                id="paint5_linear"
                x1="17.747"
                y1="36.46"
                x2="56.531"
                y2="-96.446"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset=".07" stopColor="#D4AD61" />
                <stop offset=".34" stopColor="#D4AD61" />
                <stop offset="1" stopColor="#D4AD61" stopOpacity=".28" />
              </linearGradient>
              <linearGradient
                id="paint6_linear"
                x1="17.747"
                y1="36.46"
                x2="56.531"
                y2="-96.446"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset=".07" stopColor="#D4AD61" />
                <stop offset=".34" stopColor="#D4AD61" />
                <stop offset="1" stopColor="#D4AD61" stopOpacity=".28" />
              </linearGradient>
              <linearGradient
                id="paint7_linear"
                x1="17.747"
                y1="36.46"
                x2="56.531"
                y2="-96.446"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset=".07" stopColor="#D4AD61" />
                <stop offset=".34" stopColor="#D4AD61" />
                <stop offset="1" stopColor="#D4AD61" stopOpacity=".28" />
              </linearGradient>
              <linearGradient
                id="paint8_linear"
                x1="17.747"
                y1="36.46"
                x2="56.531"
                y2="-96.446"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset=".07" stopColor="#D4AD61" />
                <stop offset=".34" stopColor="#D4AD61" />
                <stop offset="1" stopColor="#D4AD61" stopOpacity=".28" />
              </linearGradient>
            </defs>
          </svg>

          <p className="mt-6 text-gray-500 text-base leading-6">
            Frisörmästare i centrala Ljusdal med över 20 års gemensam
            erfarenhet. Du kan enkelt boka din tid via vår onlinebokning, dygnet
            runt.
          </p>
          <div className="mt-8 flex">
            <a
              href="https://www.facebook.com/Studio-Ess-102055798112809/"
              rel="noopener noreferrer"
              target="_blank"
              className="text-gray-400 hover:text-white"
            >
              <span className="sr-only">Facebook</span>
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                <path
                  fillRule="evenodd"
                  d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
            <a
              href="https://instagram.com/studioess_ljusdal"
              rel="noopener noreferrer"
              className="ml-6 text-gray-400 hover:text-white"
            >
              <span className="sr-only">Instagram</span>
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                <path
                  fillRule="evenodd"
                  d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
          </div>
        </div>
        <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
          <div className="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                Hitta hit
              </h4>
              <ul className="mt-2">
                <a
                  href="https://www.hitta.se/kartan!~61.83140,16.09470,14z/tr!i=hPvTymqa/search!i=1012344223!q=bjur%C3%A5kersv%C3%A4gen%2023a!t=single!st=plc!ai=1012344223!aic=61.83140:16.09470?search=bjur%C3%A5kersv%C3%A4gen+23a&st=combined&sst=plc&sids=1012344223&srb=0"
                  rel="noopener noreferrer"
                  className="text-base leading-6 text-gray-500 hover:text-white"
                >
                  Bjuråkersvägen 23A
                </a>
                <li className="mt-2">
                  <span className="text-base leading-6 text-gray-500 hover:text-white">
                    82731 Ljusdal
                  </span>
                </li>
                <li className="mt-2">
                  <span className="text-base leading-6 text-gray-500 hover:text-white">
                    ‭0651-300 555‬
                  </span>
                </li>
              </ul>
            </div>
            <div className="mt-12 md:mt-0">
              <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                Öppetider
              </h4>
              <ul className="mt-4">
                <li className="text-base leading-6 text-gray-500 hover:text-white">
                  Måndag 8.00-18.00
                </li>
                <li className="mt-2 text-base leading-6 text-gray-500 hover:text-white">
                  Tisdag 8.00-18.00
                </li>
                <li className="mt-2 text-base leading-6 text-gray-500 hover:text-white">
                  Onsdag 8.00-18.00
                </li>
                <li className="mt-2 text-base leading-6 text-gray-500 hover:text-white">
                  Torsdag 8.00-18.00
                </li>
                <li className="mt-2 text-base leading-6 text-gray-500 hover:text-white">
                  Fredag 8.00-12.00
                </li>
              </ul>
            </div>
          </div>
          <div className="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                Företaget
              </h4>
              <ul className="mt-4">
                <li>
                  <Link
                    to="/booking-terms"
                    className="text-base leading-6 text-gray-500 hover:text-white"
                  >
                    Bokningsvillkor
                  </Link>
                </li>
                <li className="mt-2">
                  <Link
                    to="/"
                    className="text-base leading-6 text-gray-500 hover:text-white"
                  >
                    Produkter
                  </Link>
                </li>
                <li className="mt-2">
                  <Link
                    to="/om-oss"
                    className="text-base leading-6 text-gray-500 hover:text-white"
                  >
                    Frisörer
                  </Link>
                </li>
                <li className="mt-2">
                  <Link
                    to="/lashochbrowlift"
                    className="text-base leading-6 text-gray-500 hover:text-white"
                  >
                    Lash & Brow lift
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-12 border-t border-gray-200 pt-8">
        <p className="text-base leading-6 text-gray-400 xl:text-center">
          &copy; 2021 Studio Ess AB. Alla rättigheter reserverade.
        </p>
      </div>
    </footer>
  )
}

export default Footer
